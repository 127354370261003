"use strict";

((utils): void => {
    function updateTopItemsPosition(): void {
        let topItems: HTMLElement = document.querySelector(".top-items-split");

        if (!topItems) {
            return;
        }

        if (utils.isMinWidth(768)) {
            let rect: DOMRect = topItems.getBoundingClientRect();
            let defaultHeight: number = topItems.clientHeight;
            let skewHeight: number = rect.height;
            let translate: number = (skewHeight - defaultHeight) / 2;
            topItems.style.setProperty("transform", `skewY(-3deg) translateY(-${translate}px)`);
        } else {
            topItems.style.setProperty("transform", "");
        }
    }

    window.addEventListener("load", (): void => {
        updateTopItemsPosition();
    });

    let resizeTimeout: any = null;
    window.addEventListener("resize", (): void => {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout((): void => {
            updateTopItemsPosition();
        }, 250);
    });
})((<any>window).Arcas.Utils);
