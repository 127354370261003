"use strict";

((utils): void => {
    // Open the first sub-menu when mega menu is opened
    document.querySelector(".navbar-nav .mega-menu .dropdown-toggle")?.addEventListener("shown.bs.dropdown", (): void => {
        if (utils.isMinWidth(992)) {
            // The timeout serves as a sort of queue and ensures that callback is called after this event (and other BS events) are finished
            setTimeout((): void => {
                bootstrap.Dropdown.getOrCreateInstance(document.querySelector(".navbar-nav .mega-menu .dropstart .dropdown-toggle")).show();
            }, 0);
        }
    });

    // Adjust mega menu size based on currently opened sub-menu
    let megaMenuHeight: number = 0;
    let lastVisibleSubmenu: HTMLElement = null;
    const submenus: NodeListOf<HTMLElement> = document.querySelectorAll(".navbar-nav .mega-menu .dropstart .dropdown-toggle");
    [...submenus].map((submenu: HTMLElement): void => {
        submenu.addEventListener("shown.bs.dropdown", (e: Event): void => {
            let target: HTMLElement = (<HTMLElement>e.target);
            let container: HTMLElement = target.closest(".dropdown-menu");

            if (utils.isMinWidth(992)) {
                // The timeout serves as a sort of queue and ensures that callback is called after this event (and other BS events) are finished
                setTimeout((): void => {
                    let submenus: NodeListOf<HTMLElement> = container.querySelectorAll(".dropdown-menu");

                    submenus.forEach((submenu: HTMLElement): void => {
                        if (submenu.clientHeight > megaMenuHeight) {
                            megaMenuHeight = submenu.clientHeight;
                        }
                    });

                    submenus.forEach((submenu: HTMLElement): void => {
                        submenu.style.setProperty("height", `${megaMenuHeight}px`);
                    });

                    container.style.setProperty("height", `${megaMenuHeight}px`);
                }, 0);
            } else {
                container.style.setProperty("height", "auto");
            }
        });

        submenu.addEventListener("hidden.bs.dropdown", (): void => {
            if (utils.isMinWidth(992)) {
                // The timeout serves as a sort of queue and ensures that callback is called after this event (and other BS events) are finished
                setTimeout((): void => {
                    let noneVisible: boolean = true;

                    submenus.forEach((submenu: HTMLElement): void => {
                        if (submenu.classList.contains("show")) {
                            noneVisible = false;
                        }
                    });

                    if (noneVisible && lastVisibleSubmenu) {
                        bootstrap.Dropdown.getOrCreateInstance(lastVisibleSubmenu).show();
                    }
                }, 0);
            }
        });

        submenu.addEventListener("mouseenter", (e: Event): void => {
            if (utils.isMinWidth(992)) {
                // The timeout serves as a sort of queue and ensures that callback is called after this event (and other BS events) are finished
                setTimeout((): void => {
                    let target: HTMLElement = (<HTMLElement>e.target);

                    if (!lastVisibleSubmenu) {
                        lastVisibleSubmenu = target;
                        return;
                    }

                    if (target !== lastVisibleSubmenu && lastVisibleSubmenu.classList.contains("show")) {
                        bootstrap.Dropdown.getOrCreateInstance(lastVisibleSubmenu).hide();
                    }

                    lastVisibleSubmenu = target;
                }, 0);
            }
        });
    });
})((<any>window).Arcas.Utils);
