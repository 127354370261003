"use strict";

// @ts-ignore
import gsap from "gsap";

(() => {
    let el: JQuery = $(".countdown"), countdown_interval: any = null, total_seconds: number = 0;

// Initialize the countdown
    function startCountdown() {
        let days = el.find(".bloc-time.days .figure").eq(0),
            hours = el.find(".bloc-time.hours .figure").eq(0),
            mins = el.find(".bloc-time.min .figure").eq(0),
            secs = el.find(".bloc-time.sec .figure").eq(0);

        let val_days: number = Number(days.parent().attr("data-init-value")),
            val_hours: number = Number(hours.parent().attr("data-init-value")),
            val_minutes: number = Number(mins.parent().attr("data-init-value")),
            val_seconds: number = Number(secs.parent().attr("data-init-value"));

        if (val_days < 10) days.eq(0).find(".top, .bottom").html("0" + val_days);
        if (val_hours < 10) hours.eq(0).find(".top, .bottom").html("0" + val_hours);
        if (val_minutes < 10) mins.eq(0).find(".top, .bottom").html("0" + val_minutes);
        if (val_seconds < 10) secs.eq(0).find(".top, .bottom").html("0" + val_seconds);

        total_seconds = val_days * 60 * 60 * 24 + val_hours * 60 * 60 + (val_minutes * 60) + val_seconds;

        countdown_interval = setInterval(function () {

            if (total_seconds > 0) {

                --val_seconds;

                if (val_minutes >= 0 && val_seconds < 0) {

                    val_seconds = 59;
                    --val_minutes;
                }

                if (val_hours >= 0 && val_minutes < 0) {

                    val_minutes = 59;
                    --val_hours;
                }

                if (val_days >= 0 && val_hours < 0) {

                    val_hours = 23;
                    --val_days;
                }

                // Update DOM values
                // Days
                checkHour(val_days, days);

                // Hours
                checkHour(val_hours, hours);

                // Minutes
                checkHour(val_minutes, mins);

                // Seconds
                checkHour(val_seconds, secs);

                --total_seconds;
            } else {
                clearInterval(countdown_interval);
            }
        }, 1000);
    }

    function checkHour(value: number, el: JQuery) {
        let fig_value = el.find(".top").html();

        if (Number(fig_value) != value) {
            if (value >= 10) animateFigure(el, value.toString());
            else animateFigure(el, 0 + "" + value);
        }
    }

    function animateFigure(el: JQuery, value: string) {
        let top = el.find(".top"),
            bottom = el.find(".bottom"),
            back_top = el.find(".top-back"),
            back_bottom = el.find(".bottom-back");

        // Before we begin, change the back value
        back_top.find("span").html(value);

        // Also change the back bottom value
        back_bottom.find("span").html(value);

        // Then animate
        gsap.to(top, 0.8, {
            rotationX: "-180deg",
            transformPerspective: 500,
            onComplete: function () {

                top.html(value);

                bottom.html(value);

                gsap.set(top, {rotationX: 0});
            }
        });

        gsap.to(back_top, 0.8, {
            rotationX: 0,
            transformPerspective: 500,
            onComplete: function () {
                gsap.set(back_top, {rotationX: "180deg"});
            }
        });
    }

    startCountdown();

    let mqLg = window.matchMedia("(min-width: 1250px)");
    let mqSm = window.matchMedia("(min-width: 576px) and (max-width: 768px)");
    let mqXs = window.matchMedia("(max-width: 576px)");

    $(window).on("resize load", function () {
        if (mqLg.matches) {
            removeNegativeAlign();
            if (window.innerWidth % 2 != 0) {
                removeAlign();
            } else {
                addAlign();
            }
        } else if (mqSm.matches) {
            removeAlign();
            if (window.innerWidth % 2 != 0) {
                addNegativeAlign();
            } else {
                removeNegativeAlign();
            }
        } else if (!mqXs.matches) {
            removeNegativeAlign();
            if (window.innerWidth % 2 != 0) {
                removeAlign();
            } else {
                addAlign();
            }
        } else {
            removeAlign();
            removeNegativeAlign();
        }
    })

    function addAlign() {
        $(".countdown .top, .countdown .top-back").addClass("align");
    }

    function addNegativeAlign() {
        $(".countdown .top, .countdown .top-back").addClass("negative-align");
    }

    function removeAlign() {
        $(".countdown .top, .countdown .top-back").removeClass("align");
    }

    function removeNegativeAlign() {
        $(".countdown .top, .countdown .top-back").removeClass("negative-align");
    }
})();
