"use strict";

(() => {
    const $carouselWhatWeDo = $("#carousel-what-we-do");
    const $carouselIndex = $("#carousel-index");

    if ($carouselWhatWeDo.length != 0) {
        let totalItems = $("#carousel-what-we-do .carousel-item").length;
        let currentIndex = $("#carousel-what-we-do div.active").index() + 1;
        $carouselIndex.html("" + currentIndex + "/" + totalItems + "");

        $carouselWhatWeDo.on("slid.bs.carousel", function () {
            currentIndex = $("div.active").index() + 1;
            $carouselIndex.html("" + currentIndex + "/" + totalItems + "");
        });
    }
})();
