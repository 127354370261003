"use strict";

(() => {
    const mq = window.matchMedia("(min-width: 992px)");
    const slidesToShow: number = 5;

    $(window).on("load resize", () => {
        if (mq.matches) {
            doubleSlides();
        }
    });

    window.addEventListener("DOMContentLoaded", () => {
        const $clientsCarousel = $(".clients-carousel");
        // @ts-ignore
        $clientsCarousel.slick({
            infinite: true,
            arrows: false,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            centerMode: true,
            swipe: false,
            centerPadding: 0,
            initialSlide: 3,
            speed: 900,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                    }
                },
            ]
        });

        $clientsCarousel.on("click", ".slick-slide", function (e) {
            if (mq.matches) {
                e.stopPropagation();
                let index = $(this).data("slick-index");
                // @ts-ignore
                if ($clientsCarousel.slick("slickCurrentSlide") !== index) {
                    e.preventDefault();
                    if (index <= -2) {
                        index += $(".clients-carousel .client-item").length;
                    }
                    // @ts-ignore
                    $(".clients-carousel").slick("slickGoTo", index);
                }
            }
        });
    });

    function doubleSlides() {
        const slides: JQuery = $(".clients-carousel .client-item");
        if (slides.length > 1 && slides.length <= slidesToShow) {
            let slide: JQuery;
            slides.each(function () {
                slide = $(this).clone(true)
                    .insertAfter(slide || slides.last())
                    .addClass("slick-cloned-2");
            });
        }
    }

    doubleSlides();
})();
