"use strict";

(() => {
    let historyPlayed: boolean = false;
    const $historyYear = $(".history-year");

    $(window).on("scroll", function () {
        if ($historyYear.length !== 0) {
            let end_years_pos = $historyYear.last().offset().top + $historyYear.last().height();
            let scroll_pos = window.scrollY + window.innerHeight;

            let time: number = 0;
            if (scroll_pos > end_years_pos && historyPlayed == false) {
                historyPlayed = true;
                $historyYear.each(() => {
                    setTimeout(function () {
                        $(this).find(".history-circle").addClass("active");
                    }, time);
                    time += 180;
                });

                $historyYear.each(() => {
                    setTimeout(function () {
                        $(this).find(".history-circle").removeClass("active");
                    }, time);
                    time += 130;
                });
            } else if (scroll_pos < end_years_pos) {
                historyPlayed = false;
            }
        }

    })

    if ($historyYear.length !== 0) {
        $historyYear.on("mouseenter", function () {
            $(this).find(".history-circle").addClass("active");
        });
        $historyYear.on("mouseleave", function () {
            $(this).find(".history-circle").removeClass("active");
        });
    }
})();
