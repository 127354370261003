"use strict";

((): void => {
    (<any>window).Arcas = (<any>window).Arcas || {};

    (<any>window).Arcas.Calendar = class {
        date: Date = null;
        currentDate: Date = null;
        events: any[] = [];
        weekDaysAbbreviation: string[] = ["M", "T", "W", "T", "F", "S", "S"];
        monthNames: string[] = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        constructor() {
            if (!document.querySelector(".calendar")) {
                return;
            }
            this.navigateToPreviousMonth = this.navigateToPreviousMonth.bind(this);
            this.navigateToNextMonth = this.navigateToNextMonth.bind(this);
            this.date = new Date();
            this.currentDate = new Date();
            this.renderCalendarSkeleton();
            this.update();
        }

        /**
         * Add calendar event
         * @param {number} year Full year
         * @param {number} month Month number, starting from 1
         * @param {number} day Day number, starting from 1
         */
        addEvent(year: number, month: number, day: number): void {
            month--;
            if (!this.events[year]) {
                this.events[year] = [];
            }
            if (!this.events[year][month]) {
                this.events[year][month] = [];
            }
            this.events[year][month][day] = 1;
            this.update();
        }

        update(): void {
            this.render();
            this.attachDomEvents();
        }

        render(): void {
            let calendarBody: HTMLElement = document.querySelector(".calendar .calendar-body");
            calendarBody.innerHTML = "";
            this.renderYear();
            this.renderMonth();
            this.renderDayNames();

            let day: number = 1;
            let calendarDays: number = this.getDaysInMonth(this.date.getFullYear(), this.date.getMonth() + 1) + this.getFirstDayIndex();

            for (let i: number = 0; i < calendarDays; i++) {
                if (i < this.getFirstDayIndex()) {
                    calendarBody.innerHTML += `<div class="prev-dates"></div>`;
                } else {
                    calendarBody.innerHTML += `<div class="number-item" data-num=${day}><div class="dateNumber">${day}</div></div>`;
                    day++;
                }
            }

            this.renderToday();
            this.renderEvents();
        }

        getDaysInMonth(year: number, month: number): number {
            return new Date(year, month, 0).getDate();
        }

        getFirstDayIndex(): number {
            return new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay() - 1 % 7;
        }

        renderCalendarSkeleton(): void {
            document.querySelector(".calendar").innerHTML += `
<div class="calendar-inner">
    <div class="calendar-info d-flex mb-3">
        <div class="calendar-year-month">
            <div class="calendar-month-label me-1"></div>
            <div class="calendar-year-label"></div>
        </div>
    </div>
    <div class="calendar-body"></div>
    <div class="calendar-controls">
        <div class="calendar-prev calendar-link"><div class="month-name"></div></div>
        <div class="calendar-next calendar-link"><div class="month-name"></div></div>
    </div>
</div>
`;
        }

        renderYear(): void {
            document.querySelector(".calendar .calendar-year-label").innerHTML = this.date.getFullYear().toString();
        }

        renderMonth(): void {
            document.querySelector(".calendar .calendar-month-label").innerHTML = this.monthNames[this.date.getMonth()];
        }

        renderDayNames(): void {
            this.weekDaysAbbreviation.forEach((dayName: string): void => {
                document.querySelector(".calendar .calendar-body").innerHTML += `<div class="day-item">${dayName}</div>`;
            });
        }

        renderToday(): void {
            const items: NodeListOf<HTMLElement> = document.querySelectorAll(".number-item");
            if (
                this.date.getFullYear() === this.currentDate.getFullYear() &&
                this.date.getMonth() === this.currentDate.getMonth() &&
                items
            ) {
                items[this.date.getDate() - 1].classList.add("calendar-today");
            }
        }

        renderEvents(): void {
            if (this.events[this.date.getFullYear()] && this.events[this.date.getFullYear()][this.date.getMonth()]) {
                const events = this.events[this.date.getFullYear()][this.date.getMonth()];
                for (let i: number = 0; i < this.getDaysInMonth(this.date.getFullYear(), this.date.getMonth()); i++) {
                    if (events[i + 1]) {
                        document.querySelectorAll(".number-item")[i].classList.add("event");
                    }
                }
            }
        }

        attachDomEvents(): void {
            document.querySelector(".calendar .calendar-prev.calendar-link").addEventListener("click", this.navigateToPreviousMonth);
            document.querySelector(".calendar .calendar-prev .month-name").innerHTML = this.monthNames[(12 + this.date.getMonth() - 1) % 12].substring(0, 3);
            document.querySelector(".calendar .calendar-next.calendar-link").addEventListener("click", this.navigateToNextMonth);
            document.querySelector(".calendar .calendar-next .month-name").innerHTML = this.monthNames[(12 + this.date.getMonth() + 1) % 12].substring(0, 3);
        }

        navigateToPreviousMonth(): void {
            this.date.setMonth(this.date.getMonth() - 1);
            this.update();
        }

        navigateToNextMonth(): void {
            this.date.setMonth(this.date.getMonth() + 1);
            this.update();
        }
    }
})();
