"use strict";

(() => {
    $(window).on("resize load", function () {
        const $newsDetailDateContainer = $(".news-detail-date-container");
        const $newsDetailImageTop = $(".news-detail-image-top");

        if ($newsDetailDateContainer.length !== 0 && $newsDetailImageTop.length !== 0) {
            let mq = window.matchMedia("(max-width: 767.98px)");
            if (mq.matches) {
                let top: number = $newsDetailImageTop.height() + 10;
                $newsDetailDateContainer.css("top", "-" + top + "px");
            }
        }

        let mq = window.matchMedia("(max-width: 575.98px)");

        if (mq.matches) {
            const $newsDetailTagsContainer = $(".news-detail-image-top .news-detail-tags-container");
            if ($newsDetailTagsContainer.length !== 0) {
                $newsDetailTagsContainer.detach().prependTo($(".news-detail-article-container"));
            }
        } else {
            const $newsDetailTagsContainer = $(".news-detail-article-container .news-detail-tags-container");
            if ($newsDetailTagsContainer.length !== 0) {
                $newsDetailTagsContainer.detach().appendTo($newsDetailImageTop);
            }
        }
    });
})();
