"use strict";

((): void => {
    function clamp(num: number, min: number, max: number): number {
        return Math.min(Math.max(num, min), max);
    }

    function updateParallaxItemsHeight(): void {
        document.querySelectorAll("[data-type='parallax']").forEach((item: HTMLElement): void => {
            let depth: number = parseFloat(item.getAttribute("data-depth"));
            if (depth < 1) {
                depth++;
                depth *= 100;
            }
            depth = clamp(depth, 100, 125);
            item.style.setProperty("height", `${depth}vh`);
        });
    }

    window.addEventListener("load", (): void => {
        updateParallaxItemsHeight();
    });

    let windowResizeTimeout: any = null;
    window.addEventListener("resize", (): void => {
        clearTimeout(windowResizeTimeout);
        windowResizeTimeout = setTimeout((): void => {
            updateParallaxItemsHeight();
        }, 250);
    });

    window.addEventListener("scroll", (): void => {
        document.querySelectorAll("[data-type='parallax']").forEach((value: HTMLElement): void => {
            let topDistance: number = window.scrollY;
            let depth: number = parseFloat(value.getAttribute("data-depth"));
            let movement: number = -topDistance * depth;
            let translateY: string = `translateY(${movement}px)`;
            value.style.setProperty("transform", translateY);
        });
    });
})();
