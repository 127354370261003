"use strict";

(() => {
    let actualSize: number;
    let countDownToTime: number;
    let time: number;
    let ctx: CanvasRenderingContext2D;
    const ctxArray: Array<CanvasRenderingContext2D> = [];
    const rings: { [key: string]: any } = {
        "DAYS": {
            s: 86400000, // mseconds in a day,
            max: 365
        },
        "HOURS": {
            s: 3600000, // mseconds per hour,
            max: 24
        },
        "MINUTES": {
            s: 60000, // mseconds per minute
            max: 60
        },
        "SECONDS": {
            s: 1000,
            max: 60
        }
    }

    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 5);

    const ringer = {
        countDownTo: futureDate.toLocaleDateString("en-US"),
        ringSize: 190, // px
        ringThickness: 6, // px
        updateInterval: 11, // ms
    };

    function go() {
        let idx = 0;

        time = (new Date().getTime()) - countDownToTime;

        for (let rKey in rings) unit(idx++, rKey, rings[rKey]);

        setTimeout(go, ringer.updateInterval);
    }

    function unit(idx: number, label: string, ring: { s: any; max: number; }) {
        let x, y, value;
        const ring_secs = ring.s;
        value = time / ring_secs;
        time -= Math.round(parseInt(value.toString())) * ring_secs;
        value = Math.abs(value);

        x = (ringer.ringSize * .5 + ringer.ringThickness * .5);
        y = ringer.ringSize * .5;
        y += ringer.ringThickness * .5;

        // calculate arc end angle
        const degrees = 270 + (value / ring.max) * 360.0;
        const endAngle = degrees * (Math.PI / 180);

        ctxArray[idx].save();

        ctxArray[idx].translate(x, y);
        ctxArray[idx].clearRect(actualSize * -0.5, actualSize * -0.5, actualSize, actualSize);

        // first circle
        ctxArray[idx].strokeStyle = "rgba(128,128,128,0.2)";
        ctxArray[idx].beginPath();
        ctxArray[idx].arc(0, 0, ringer.ringSize / 2, 0, 2 * Math.PI);
        ctxArray[idx].lineWidth = ringer.ringThickness;
        ctxArray[idx].stroke();

        // second circle
        ctxArray[idx].strokeStyle = "#FBB907";
        ctxArray[idx].beginPath();
        ctxArray[idx].arc(0, 0, ringer.ringSize / 2, 1.5 * Math.PI, endAngle);
        ctxArray[idx].lineWidth = ringer.ringThickness;
        ctxArray[idx].stroke();

        // label
        ctxArray[idx].fillStyle = "#808080";
        ctxArray[idx].font = "15px Poppins";
        ctxArray[idx].fillText(label, 0, 45);
        ctxArray[idx].fillText(label, 0, 45);

        ctxArray[idx].fillStyle = "black";
        ctxArray[idx].font = "bold 4em Poppins";
        ctxArray[idx].fillText(Math.floor(value).toString(), 0, 20);

        ctxArray[idx].restore();
    }

    function ringerInit() {
        const size = {
            w: (ringer.ringSize + ringer.ringThickness),
            h: (ringer.ringSize + ringer.ringThickness)
        };

        actualSize = ringer.ringSize + ringer.ringThickness;

        for (let i = 0; i < 4; i++) {
            const counterPartDiv = document.createElement("div");
            counterPartDiv.setAttribute("class", "countdown-ring col-6 col-md-3");
            $(".countdown-rings").append(counterPartDiv);

            const cvs = document.createElement("canvas");
            cvs.setAttribute("width", size.w.toString());
            cvs.setAttribute("height", size.h.toString());
            ctx = cvs.getContext("2d");
            counterPartDiv.append(cvs);
            ctx.textAlign = "center";
            countDownToTime = new Date(ringer.countDownTo).getTime();
            ctxArray.push(ctx);
        }

        go();
    }

    ringerInit();
})();
