"use strict";

(() => {
    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;

    let futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 5);

    let countDownDate = new Date(futureDate.toLocaleDateString("en-us")).getTime();

    // Update the count-down every 1 second
    const x = setInterval(function () {

        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the count-down date
        const timeDiff = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(timeDiff / (DAY));
        const hours = Math.floor((timeDiff % (DAY)) / (HOUR));
        const minutes = Math.floor((timeDiff % (HOUR)) / (MINUTE));
        const seconds = Math.floor((timeDiff % (MINUTE)) / SECOND);

        const $timePartDays = $(".time-part #days");
        const $timePartHours = $(".time-part #hours");
        const $timePartMinutes = $(".time-part #minutes");
        const $timePartSeconds = $(".time-part #seconds");

        $timePartDays.html(days.toString());
        $timePartHours.html(hours.toString());
        $timePartMinutes.html(minutes.toString());
        $timePartSeconds.html(seconds.toString());

        if (timeDiff < 0) {
            clearInterval(x);
            $timePartDays.html("0");
            $timePartHours.html("0");
            $timePartMinutes.html("0");
            $timePartSeconds.html("0");
        }

    }, 1000);
})();
