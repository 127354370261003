"use strict";

(() => {
    let specializationLoaded: boolean = false;

    $(window).on("scroll load", function () {
        const $about = $(".about-top-specialization-line");
        if ($about.length != 0 && !specializationLoaded) {
            let specialization_pos = $about.offset().top;
            let scroll_pos = window.scrollY + (window.innerHeight / 1.2);

            if (scroll_pos > specialization_pos) {
                $(".about-top-specialization-line-used").each(function () {
                    let used = this.getAttribute("data-value") + "%";
                    $(this).css("width", used);

                    let left = $(this).css("width");
                    $(this).css({left: "-" + left}).animate({"left": "0"}, "slow");
                    specializationLoaded = true;
                })
            }
        }
    });
})();
